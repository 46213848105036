import {EthNetwork} from "@/assets/lib/networks";
import { Dumbapp } from "@blockwell/dumbapp";
import {Batcher} from "@/lib/eth/Batcher";
import { Chain } from "@blockwell/chain-client";
import {Happ} from "@/views/happs/Happ";
import BigNumber from "bignumber.js";
import moment, {Moment} from "moment";
import abi from "../../../../assets/data/Hourglass.abi.json";

export class HourglassDynamic {
}

export class HourglassData extends HourglassDynamic {
}

// For some reason Babel doesn't like sub-sub-classes, so dynamic doesn't inherit from this
export class HourglassAccount {
}

export async function loadHourglass(happ, account) {
    let batcher = new Batcher(happ.web3);

    batcher.setContract(abi, happ.address);

    batcher
        .add('name')
        .add('symbol')
        .addBig('totalSupply')
        .addBig('bwver')
        .addBig('bwtype')
        .addInt('currentPeriod')
        .addTimestamp('periodStart')
        .addInt('periodLength')
        .addBig('freeStakeLimit')
        .addBig('periodStakeLimit')
        .addBig('periodTotalValue')


    if (account) {
        batcher.addBoolean('isAdmin', 'admin', [account])
            .addBig('balanceOf', 'balance', [account])
            .addBig('availableBalanceOf', 'availableBalance', [account])
            .addBig('stakeOf', 'stake', [account])
            .addBig('timeOf', 'time', [account])
            .addBig('autoStakeOf', 'autoStake', [account])
    }

    let data = await batcher.execute(HourglassData);

    data.address = happ.address;
    data.network = happ.network;
    data.contractId = happ.contractId;
    data.periodEnds = data.periodStart.clone().add(data.periodLength, 'seconds');

    return data;
}

export async function updateHourglass(happ, data, account) {
    let batcher = new Batcher(happ.web3);
    batcher.setContract(abi, happ.address);

    batcher
        .addInt('currentPeriod')
        .addTimestamp('periodStart')
        .add('getAccounts');

    if (account) {
        batcher.addBoolean('isAdmin', 'admin', [account])
            .addBig('balanceOf', 'balance', [account])
            .addBig('availableBalanceOf', 'availableBalance', [account])
            .addBig('stakeOf', 'stake', [account])
            .addBig('timeOf', 'time', [account])
            .addBig('autoStakeOf', 'autoStake', [account])
    }

    let update = await batcher.execute(HourglassDynamic);
    if (data.currentPeriod !== update.currentPeriod) {
        update.periodEnds = update.periodStart.clone().add(data.periodLength, 'seconds');
    }

    if (data.admin && update.getAccounts.length > 0) {
        console.log(update);
        let batcher = new Batcher(happ.web3);
        batcher.setContract(abi, happ.address);
        update.accounts = await loadHourglassAccounts(batcher, update.getAccounts);
    }

    return update;
}

export async function loadHourglassAccounts(batcher, addresses) {
    let argsList = addresses.map(it => [it]);

    batcher.addObjectList('accounts', addresses.length, {
        address: {
            valueList: addresses
        },
        balance: {
            method: 'balanceOf',
            argsList,
            type: "bignumber"
        },
        availableBalance: {
            method: 'availableBalanceOf',
            argsList,
            type: "bignumber"
        },
        stake: {
            method: 'stakeOf',
            argsList,
            type: "bignumber"
        },
        time: {
            method: 'timeOf',
            argsList,
            type: "bignumber"
        },
        autoStake: {
            method: 'autoStakeOf',
            argsList,
            type: "bignumber"
        },
        admin: {
            method: 'isAdmin',
            argsList,
            type: "boolean"
        }
    }, 0, HourglassAccount);

    let res = await batcher.execute();

    return res.accounts;
}

export async function loadTimes(happ, data) {
    const contract = new happ.web3.eth.Contract(abi, data.address);
    let events = await contract.getPastEvents('TimeBurn', {
        fromBlock: 0,
        toBlock: 'latest'
    });

    let days = {};
    let totals = {};
    let total = new BigNumber(0);

    for (let it of events) {
        totals[it.returnValues.note] = new BigNumber(it.returnValues.value).div('1e18').plus(totals[it.returnValues.note] || 0);
    }

    for (let key of Object.keys(totals)) {
        total = total.plus(totals[key]);
        totals[key] = totals[key].toString(10);
    }

    return {
        total,
        totals,
        events
    }
}
