<template>
    <v-popover class="contract-dropdown-container" popover-class="contract-dropdown" placement="bottom">
        <div class="tooltip-target">
            <h3 class="contract-name">{{name}}</h3>
            <a href="javascript: void 0">View Contract Info</a> <span class="down-arrow">▼</span>
        </div>
        <template #popover>
            <div class="contract-dropdown-menu">
                <div class="data twoline">
                    <div class="label">Name</div>
                    <div
                        class="data-value">{{name}}
                    </div>
                </div>
                <template v-if="contracts">
                    <div v-for="c in contracts" class="data twoline">
                        <div class="label">{{ c.name }}</div>
                        <div class="data-value">
                            <Clipboard :address="c.address" :network="network"/>
                        </div>
                    </div>
                </template>
                <div v-else class="data twoline">
                    <div class="label">Contract Address</div>
                    <div class="data-value">
                        <Clipboard :address="address" :network="network"/>
                    </div>
                </div>
                <div class="data twoline">
                    <div class="label">Network</div>
                    <div class="data-value">{{network}}</div>
                </div>
                <button class="btn btn-sm btn-primary" @click="changeContract">Change Contract</button>
            </div>
        </template>
    </v-popover>
</template>

<script>
    import Clipboard from "@/components/Clipboard";

    export default {
        name: "ContractDropdown",
        components: {Clipboard},
        props: {
            name: String,
            address: String,
            network: [String, Number],
            contracts: Array
        },
        data() {
            return {};
        },
        methods: {
            changeContract() {
                this.$emit('change-contract');
            }
        }
    }
</script>

<style lang="scss">
@import "~@/assets/css/custom";

    .contract-dropdown-container {
        margin-left: 50px;

        .down-arrow {
            color: #fff;
            font-size: 0.8em;
        }

        .contract-name {
            white-space: normal;


            @media screen and (max-width: 460px) {
                font-size: 1.5rem;
                line-height: 1.05;
                margin-bottom: 0;
            }
        }
    }

    .contract-dropdown {

        .contract-dropdown-menu {
            @include mdElevation(5);
            border: none;
            border-radius: 3px;
            padding: 16px;

            .contract-address {
                white-space: nowrap;
            }
        }
    }
</style>
