<template>
    <layout-heading>
        <portal to="title">
            <router-link to="/nft">
                <img src="../../../assets/images/icons/qr.svg" alt="">
                <span>Hourglass</span>
            </router-link>
        </portal>
        <template #heading-cta>
            <ContractDropdown v-if="contractLoaded"
                              @change-contract="reset"
                              :name="hourglass.name"
                              :address="address"
                              :network="network"
            />
        </template>

        <p>
            <router-link to="/9x9dwo">Create an Hourglass contract</router-link>
            <br>
            <a href="https://youtu.be/3ymendGLOm4" target="_blank">Demo Video</a>
        </p>

        <div v-if="errorMessage" class="results error">{{ errorMessage }}</div>

        <ContractLoader v-if="!contractLoaded"
                        name="Hourglass"
                        path="hourglass"
                        features="hourglass"
                        :deployer="deployerAddress"
                        :common="commonContracts"/>
        <div v-else-if="!account">
            <button class="btn btn-primary" @click="login">Sign In</button>
        </div>
        <div v-else>
            <h4>Summary</h4>
            <p v-if="!totalTime">You have no activity this week.</p>
            <div v-else>
                <p>
                    You've stake
                    <TokenAmount :value="hourglass.stake"/>
                    this week, which
                    gives you {{ totalTime }} of time, or <strong>{{ totalTimePercent }}%</strong> of the week.
                </p>
                <p>{{ timeUsed || "No time" }} ({{ timeUsedPercent }}%) has been used up, leaving you with {{ timeLeft }}.</p>
            </div>

            <h4>Balances</h4>
            <DataValue label="Your Total Balance">
                <TokenAmount :value="hourglass.balance"/>
                <DumbappPopover
                    v-if="hourglass.admin"
                    :instance="hourglass.dumbapps.mint"
                    label="Mint"
                />
            </DataValue>
            <DataValue label="Available Balance">
                <TokenAmount :value="hourglass.availableBalance"/>
            </DataValue>
            <DataValue label="Time Left">
                {{ timeLeft }}
                <DumbappPopover
                    :instance="hourglass.dumbapps.stake"
                    label="Stake For Time"
                />
            </DataValue>
            <p>
                You can stake up to
                <TokenAmount :value="hourglass.freeStakeLimit.minus(hourglass.stake)"/>
                more without burning tokens. 1 token = 1 minute of time.
            </p>

            <h4>Staking</h4>
            <DataValue label="Stake Tokens">
                <TokenAmount :value="hourglass.stake"/>
            </DataValue>
            <DataValue label="Auto-Staking">
                <TokenAmount :value="hourglass.autoStake"/>
                <DumbappPopover
                    :instance="hourglass.dumbapps.autoStake"
                    label="Change Auto-Staking"
                />
            </DataValue>

            <div v-if="hourglass.admin">
                <div class="table-responsive-md">
                    <table class="table table-sm">
                        <thead>
                        <tr>
                            <th>Address</th>
                            <th>Balance</th>
                            <th>Stake</th>
                            <th>Time</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="it in hourglass.accounts">
                            <td>{{ it.address }}</td>
                            <td>
                                <TokenAmount :value="it.balance"/>
                            </td>
                            <td>
                                <TokenAmount :value="it.stake"/>
                                (
                                <TokenAmount :value="it.autoStake"/>
                                )
                            </td>
                            <td>
                                <TokenAmount :value="it.time"/>
                            </td>
                            <td>
                                <DumbappPopover
                                    :instance="hourglass.dumbapps.burnTime"
                                    :values="{account: it.address}"
                                    label="Burn Time"/>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <h3>Burns</h3>
                <div class="table-responsive-md">
                    <table class="table table-sm">
                        <thead>
                        <tr>
                            <th>Address</th>
                            <th>Time</th>
                            <th>Note</th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-for="period in burnsInPeriods">
                            <tr>
                                <th colspan="2">Period #{{ period.number }}</th>
                                <td>{{renderTime(period.total)}}</td>
                            </tr>
                            <tr v-for="it in period.events">
                                <td>{{ it.account }}</td>
                                <td>{{ renderTime(it.value) }}</td>
                                <td>{{ it.note }}</td>
                            </tr>
                        </template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    </layout-heading>
</template>

<script>
import {getNetwork} from "@/assets/lib/networks";
import Clipboard from "@/components/Clipboard";
import DataValue from "@/components/DataValue";
import { DumbappSubmission } from "@blockwell/dumbapp";
import DumbappPopover from "@/components/DumbappPopover";
import Modal from "@/components/Modal";
import ProgressCircle from "@/components/ProgressCircle";
import TokenAmount from "@/components/TokenAmount";
import {preciseDiff} from "@/lib/moment-precise-range";
import ContractDropdown from "@/views/happs/components/ContractDropdown";
import ContractLoader from "@/views/happs/components/ContractLoader";
import HappMixin, {Happ} from "@/views/happs/Happ";
import {
    HourglassData,
    loadHourglass, loadTimes,
    updateHourglass
} from "@/views/happs/hourglass/lib/hourglasslib";
import BigNumber from "bignumber.js";
import moment from "moment";
import {mapGetters, mapState} from "vuex";
import * as interval from "@/lib/interval";

export default {
    components: {
        DataValue,
        Modal, ProgressCircle, DumbappPopover, Clipboard, TokenAmount, ContractLoader, ContractDropdown
    },
    mixins: [HappMixin],
    provide() {
        return {
            token: this.token
        }
    },
    data() {
        return {
            type: "hourglass",
            deployerAddress: "0x576E09e1Ab15f09B8B265eBA6643bc384D3E49FB",
            commonContracts: [],
            errorMessage: null,
            hourglass: new HourglassData(),
            interval: null,
            token: {},
            burns: {
                total: null,
                totals: [],
                events: []
            }
        };
    },
    computed: {
        ...mapState('user', ['account']),
        ...mapGetters('user', ['api']),
        ...mapGetters('dumbapp', ['tracking']),
        contractLoaded() {
            return !!this.hourglass.address;
        },
        timeLeft() {
            let t = this.hourglass.time;
            if (t?.gt(0)) {
                let now = moment();
                let time = now.clone().add(t.div('1e18').dp(0).toNumber(), "minutes");
                return preciseDiff(now, time);
            }
            return "none";
        },
        timeUsed() {
            let t = this.hourglass.time;
            if (t?.gt(0)) {
                let now = moment();
                let time = now.clone().add(
                    this.hourglass.stake.minus(t).div('1e18').dp(0).toNumber(),
                    "minutes");
                console.log(time);
                return preciseDiff(now, time);
            }
            return "no time";
        },
        timeUsedPercent() {
            let t = this.hourglass.time;
            if (t?.gt(0)) {
                return this.hourglass.stake.minus(t).div(this.hourglass.periodTotalValue).times(100).toFixed(0);
            }
            return "0";
        },
        totalTime() {
            let t = this.hourglass.stake;
            if (t?.gt(0)) {
                let now = moment();
                let time = now.clone().add(t.div('1e18').dp(0).toNumber(), "minutes");
                return preciseDiff(now, time);
            }
            return null;
        },
        totalTimePercent() {
            let t = this.hourglass.stake;
            if (t?.gt(0)) {
                return t.div(this.hourglass.periodTotalValue).times(100).toFixed(0);
            }
            return "0";
        },
        burnsInPeriods() {
            let period = {
                number: 0,
                total: new BigNumber(0),
                events: []
            };

            let periods = [period];

            for (let it of this.burns.events) {
                let burn = it.returnValues;

                if (!burn.period.eq(period.number)) {
                    period = {
                        number: period.number + 1,
                        total: new BigNumber(0),
                        events: []
                    }
                    periods.push(period);
                }

                period.total = period.total.plus(burn.value);

                period.events.push({
                    account: burn.account,
                    value: burn.value,
                    note: burn.note
                })
            }
            return periods;
        }
    },
    watch: {
        happ: {
            immediate: true,
            handler(val) {
                if (val) {
                    this.loadData(val);
                } else {
                    this.hourglass = new HourglassData();
                }
            }
        }
    },
    methods: {
        loadData(happ) {
            loadHourglass(happ, this.account)
                .then(data => {
                    this.errorMessage = null;
                    this.hourglass = data;

                    this.$set(this.token, "address", data.address);
                    this.$set(this.token, "symbol", data.symbol);
                    this.$set(this.token, "decimals", 18);

                    if (this.interval) {
                        this.interval.clear();
                    }
                    this.interval = interval.every(() => {
                        this.updateData();
                    }, 15000);

                    if (data.admin) {
                        loadTimes(happ, data)
                            .then(times => {
                                this.burns = times;
                            })
                    }

                    if (this.loggedIn) {
                        this.api.addToContractHistory(
                            this.$route.fullPath,
                            "Hourglass happ",
                            happ.address,
                            happ.network.networkId
                        );
                    }

                    return this.api.getHourglassDumbapps(data.contractId)
                        .then(dumbapps => {
                            this.hourglass.dumbapps = dumbapps;
                        })
                })
                .catch(err => {
                    console.error(err);
                    this.errorMessage = "Failed to load contract: " + err.message;
                });
        },
        updateData() {
            if (this.happ && this.happ.address === this.hourglass.address) {
                updateHourglass(this.happ, this.hourglass, this.account)
                    .then(update => {
                        this.hourglass = Object.assign({}, this.hourglass, update);
                    })
                    .catch(console.error);
            }
        },
        clear() {
            if (this.interval !== null) {
                this.interval.clear();
                this.interval = null;
            }
        },
        reset() {
            this.$router.push('/hourglass');
        },
        dumbappCompleted(submission) {
            let i = 0;
            let refresh = false;
            console.log('refresh?', submission);
            for (let step of submission.data.steps) {
                let dumbappStep = submission.dumbapp.steps[i];
                if (getNetwork(dumbappStep.network).networkId === this.hourglass.network.networkId
                    && dumbappStep.address.toLowerCase() === this.hourglass.address.toLowerCase()) {
                    refresh = true;
                }
                ++i;
            }

            console.log('refresh:', refresh);

            if (refresh) {

            }
        },
        login() {
            this.$bus.emit('login_request', {
                message: ""
            });
        },
        renderTime(val) {
            let total = new BigNumber(val).div('1e18');
            let minutes = total.mod(60);
            let hours = total.div(60).integerValue(BigNumber.ROUND_FLOOR);

            return hours + ":" + minutes;
        }
    },
    mounted() {
        this.$bus.on('dumbapp-completed', this.dumbappCompleted);
    },
    beforeDestroy() {
        this.$bus.off('dumbapp-completed', this.dumbappCompleted);
        this.clear();
    }
}
</script>

<style scoped lang="scss">

</style>
